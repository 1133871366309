export const ERROR_MESSAGES: { [key: string]: string } = {
	DUPLICATED_RESERVATION_TIME:
		'해당 예약일시의 예약건이 존재합니다.<br />내장 인원 변경은 예약실로 문의 바랍니다.',
	ZERO_DEPOSIT: '환불 가능한 선수금이 없습니다.',
	NOT_FOUND_RESERVATION_DEPOSIT: '예약 선수금이 존재하지 않습니다.',
	NOT_FOUND_RESERVATION_DEPOSIT_INCOM:
		'예약 선수금 입금 정보가 존재하지 않습니다.',
	TOO_MANY_RESERVATION_DEPOSIT_INCOME:
		'예약 선수금에 입금 정보가 다수 등록되어 있습니다. 프론트에 문의해주세요.',
	ALREADY_USED_RESERVATION_DEPOSIT: '사용된 예약 선수금이 있습니다.',
	ALREADY_PG_CANCELED:
		'이미 PG 결제가 취소된 건입니다. <br />프론트에 문의해주세요. <br /> :v',
	PG_CANCEL_ERROR: `결제 취소 중 오류가 발생했습니다.<br/> :v`,
	GENDER_MISMATCH: '조인팀의 구성원 성별이<br> 실제 성별과 다를 수 있습니다.',
	PG_APPROVE_ERROR: `결제 승인 중 오류가 발생했습니다.<br /> :v`,
	NOT_REGISTERED_PURC: '등록되지 않은 매입사입니다.',
	LESS_MIN_VALUE: '최소값보다 작습니다.',
	EXCEED_MAX_VALUE: '최대값을 초과하였습니다.',
	// EXIST_UNUSED_DPMNY: '미사용 예치금이 존재합니다.',
	// EXIST_UNUSED_DEPOSIT: '미사용 선수금이 존재합니다.',
	// CANNOT_WITHDRAW_HAS_RESERVATIONS_PENALTY:
	//   '위약이 존재하여 회원 탈퇴를 하실 수 없습니다.',
	// CANNOT_WITHDRAW_HAS_RESERVATIONS:
	//   '금일 이후 예약이 있어 회원 탈퇴를 하실 수 없습니다.',
	EXCEED_COUNT_OF_WAITING_RESERVATIONS_FOR_DAY:
		'대기예약 횟수를 초과하였습니다',
	SESSION_DUPLICATED: '중복 로그인',
	DUPLICATED_HP_NO: '입력하신 핸드폰번호가<br /> 두개이상 존재합니다.',
	NO_PASSWORD:
		'홈페이지 리뉴얼에 따른<br /> 비밀번호 재설정이 필요합니다. <br />비밀번호 재설정 화면으로 이동합니다.',
	ID_PASSWORD_MISMATCH: '아이디 또는 비밀번호가 일치하지 않습니다.',
	ID_PASSWORD_MISMATCH_FORWARD_TO_PW_FIND_VIEW:
		'아이디 또는 비밀번호가 일치하지 않습니다. <br />비밀번호 찾기화면으로 이동합니다.',
	ID_DUPLICATED:
		'동일한 아이디가 등록되어 있습니다. <br />중복 아이디조회를 통하여<br /> 다시 시도하여 주십시오',
	ID_NOT_AVAILABLE: '사용 불가능한 ID 입니다',
	RE_JOIN_RESTRICTIONS: `재가입은 :v 부터 가능합니다.`,
	USERDI_DUPLICATED:
		'동일한 명의로 회원이 등록되어 있습니다. <br />로그인하여 주십시오',
	HP_DUPLICATED: '동일한 핸드폰번호로 회원이 등록되어 있습니다.',
	INVALID_TOKEN: '유효하지 않은 토큰값입니다.',
	ONLY_REGULAR_MEMBERS_CAN_USE_IT: '정회원만 이용하실 수 있습니다.',
	SESSION_EXPIRED: '세션시간이 만료되었습니다. 재로그인 해주세요.',
	REFRESH_TOKEN_EXPIRED: '리프레쉬 토큰 만료',
	INVALID_REFRESH_TOKEN: '유효하지 않은 리프레쉬 토큰',
	INVALID_METHOD_SINATURE: 'INVALID_METHOD_SIGNATURE',
// 	INTERCEPTED_ID: `회원님은 홈페이지 사용이 제한된 상태입니다.<br />
// 홈페이지 사용을 원하시면<br /> ${ccName}로 연락주시기 바랍니다.`,
	LOGIN_ETC_ERROR: '로그인 오류 입니다. 다시 시도하여 주십시요.',
	AUTO_LOGIN_ERROR: '자동 로그인 오류',
	CANNOT_WITHDRAW_HAS_RESERVATIONS_PENALTY:
		'위약이 존재하여<br /> 회원 탈퇴를 하실 수 없습니다.',
	CANNOT_WITHDRAW_HAS_RESERVATIONS:
		'금일 이후 예약이 있어<br /> 회원 탈퇴를 하실 수 없습니다.',
	FIND_ID_NO_WEB_ID: '일치하는 회원정보를 찾을 수 없습니다.',
	// FIND_ID_DUPLICATED_WEB_ID: `아이디가 2건 이상 조회되었습니다.<br />${ccName}로 연락주시기 바랍니다.`,
	FIND_PASSWD_NO_USER: '일치하는 회원정보를 찾을 수 없습니다.',
	CHANGE_PASSWD_NOT_VALID_FIND_PASSWORD_TOKEN:
		'변경을 위한 인증정보가 잘못되었거나<br /> 인증 시간이 초과되었습니다.<br />다시 시도해 주십시오',
	NOT_VALID_HP_NO_CERTIFICATION_TOKEN:
		'인증정보가 잘못되었습니다.<br />다시 시도해 주십시오',
	NO_CRTFC_NO: '인증번호를 잘못 입력하셨습니다.',
	CRTFC_EXPIRED: '인증번호가 만료되었습니다.',
	INVALID_AUTH_CUSTOM_ERROR: '권한 처리 중 오류가 발생하였습니다.',
	NO_AUTH: '권한이 없습니다.',
	401: '권한이 없습니다.',
	FRONT_LOCK:
		'현재 프론트 정산 중입니다.<br /> 프론트에 연락 후 LOCK 해제 요청바랍니다.',
	CHECK_OUT_COMPLETE:
		'현재 체크아웃이 완료되었습니다.<br /> 체크아웃 해제 후 진행해주세요.',
	PAY_COMPLETE: '현재 정산 완료된 전표입니다.',
	PART_PAY_COMPLETE: '현재 부분 정산 완료된 전표입니다.',
	PAY_DIVIDE_COMPLETE: '현재 분할 처리 완료된 전표입니다.',
	PAYER_CHANGE_COMPLETE: '현재 지불자 변경 처리 완료된 전표입니다.',
	PAYMENT_UNCORRECTABLE:
		'해당 전표는 [지불자 변경] 또는 [분할 처리]<br /> 및 [정산]이 완료된 전표라 추가/수정이 불가능합니다.',
	CANNOT_USE_DEPOSIT_OVER_THAN_BALANCED_AMOUNT:
		'선수금 잔액 이상 사용 불가합니다.',
	NOT_FOUND_TEAM_INFO_DATA: '해당 팀 정보가 존재하지 않습니다.',
	NO_REQUIRED_DATA: '필수 데이터가 존재하지 않습니다.',
	NO_REQUIRED_SYSTEM_SETTING_VALUES: '시스템 환경설정 값이 존재하지 않습니다.',
	NO_REQUIRED_COMMON_CODE_SETTING_VALUES:
		'공통코드 설정 값이 존재하지 않습니다.',
	NO_REQUIRED_PENALTY_CODE_SETTING_VALUES:
		'위약코드 설정 값이 존재하지 않습니다.',
	NO_REQUIRED_CALENDER_VALUES: '캘린더 설정 값이 존재하지 않습니다.',
	NO_REQUIRED_BENEFITS_VALUES: '특전 설정 값이 존재하지 않습니다.',
	EXIST_UNUSED_DPMNY: '미사용 예치금이 존재합니다.',
	EXIST_UNUSED_DEPOSIT: '미사용 선수금이 존재합니다.',
	BLOCK_ID: '사용중지된 아이디입니다.<br />관리자에게 문의 바랍니다.',
	BLOCK_PASSWORD: '비밀번호 오류 횟수 초과.<br />관리자에게 문의 바랍니다.',
	MISMATCH_PASSWORD: '잘못된 비밀번호입니다.',
	CAPTCHA_ANSWER_REQUIRED: '보안문자를 입력해주세요.',
	CAPTCHA_ANSWER_INVALID: '잘못된 보안문자입니다.',
	CAPTCHA_ANSWER_INCORRECT: '보안문자가 일치하지 않습니다.',
	INPUT_DATA_INVALID: '입력 데이터가 올바르지 않습니다.',
	FAULT_STATUS_DIV: '상태 체크 구분이 잘못되었습니다.',
	ALREADY_TABLE_USING: '상태 체크 구분이 잘못되었습니다.',
	EXIST_SALES_EXCLUDE_FRONT_AND_CART:
		'입장료와 카트대여료를 제외한 매출이 존재합니다.',
	ALREADY_CADDIE_IS_ARRANGED: '해당 팀에 캐디가 배치되어 있습니다.',
	EXIST_PAY_COMPLETE_FRONT_OR_CART:
		'정산이 완료된 입장료<br /> 또는 카트대여료 내역이 존재합니다.',
	EXIST_PAYMENTLIST: '정산한 내역이 존재합니다.',
	NOT_EXPECTED_ADMISSION_FEES:
		'입력 요금코드와 결과 요금코드가 다르므로 확인이 필요합니다.',
	RESVE_TIME_EXIST: '예약된 시간입니다.',
	HOLD_TIME_EXIST: '홀딩된 시간입니다.',
	BLANK_TIME_EXIST: '숨김 처리된 시간입니다.',
	BLOCK_TIME_EXIST: '블럭 처리된 시간입니다.',
	WEB_TIME_EXIST: '웹타임 처리된 시간입니다.',
	MENU_USER_MARK_EXISTS: '이미 즐겨찾기에 추가하신 메뉴입니다.',
	AIRPORT_API_CALL_FAIL:
		'데이터 가져오기에 실패하였습니다. <br />다시 시도해 주십시오',
	ALREADY_EXIST_WEB_ID: '이미 웹아이디가 존재합니다.',
	// NOT_EXIST_MEMBER: `입력하신 회원번호의 회원이 존재하지 않습니다.<br />${ccName}로 연락주시기 바랍니다.`,
	// FIND_ID_DUPLICATED_MEMBER: `회원정보가 2건 이상 조회되었습니다.<br />${ccName}로 연락주시기 바랍니다.`,
	// FIND_ID_DUPLICATED_MEMBERSHIP: `회원권정보가 2건 이상 조회되었습니다.<br />${ccName}로 연락주시기 바랍니다.`,
	REAL_NAME_CERTIFICATE_ERROR: '실명인증 시 오류가 발생하였습니다.',
	ETC_ERROR: '서버 오류입니다. 관리자에게 문의하세요.',
	SNS_REQUIRED_ERROR: 'SNS 정보 필수 제공 항목이 미체크',
	FILE_SIZE_LIMIT_EXCEEDED: '첨부파일 크기가 큽니다.',
	CLOSED_FRONT: '프론트 일 마감이 되었습니다.',
	ALREADY_EXIST_GROUP_RECEPTION: '연단체 신청 제한 횟수 초과',
	NOT_FOUND_GROUP_RECEPTION: '해당 단체 신청이 존재하지 않습니다.',
	EXCEED_COUNT_OF_CREATABLE_TEAM: '생성 가능한 팀 수 초과',
	NOT_EXIST_GROUP_GENER_MEMBERSHIP:
		'총무가 인터넷 멤버십<br /> 회원이어야 신청 가능합니다.',
	NOT_EXIST_GROUP_CHAIR_MEMBERSHIP:
		'회장이 인터넷 멤버십<br /> 회원이어야 신청 가능합니다.',
	NOT_EXIST_GROUP_MEMBER_MEMBERSHIP:
		'단체 구성원이 인터넷 멤버십<br /> 회원이어야 신청 가능합니다.',
	NOT_FOUND_GROUP_RECEIPT_CONF: '단체 신청 기간이 아닙니다.',
	WEATHER_API_ERROR: '날씨를 불러오지 못했습니다.',
	NOT_EXIST_HP:
		'예약자 핸드폰 번호가 존재하지 않아<br /> SMS 전송이 불가합니다.',
	PENALTY_PERIOD: '위약기간',
	CONCURRENT_RESERVATIONS: '동시 예약',
	EXCEED_COUNT_OF_RESERVATIONS_FOR_DAY: '당일 예약 횟수 초과',
	EXCEED_WEEKDAY_COUNT_OF_PRE_RESERVATIONS_FOR_MONTH:
		'주중 선점갯수 초과입니다.<br /> 추가예약은 1회 라운딩 후 예약가능합니다.',
	EXCEED_WEEKEND_COUNT_OF_PRE_RESERVATIONS_FOR_MONTH:
		'주말 선점갯수 초과입니다.<br /> 추가예약은 1회 라운딩 후 예약가능합니다.',
	EXCEED_COUNT_OF_PRE_RESERVATIONS:
		'선점갯수 초과입니다.<br /> 추가예약은 1회 라운딩 후 예약가능합니다.',
	EXCEED_COUNT_OF_WEEKDAY_AVAILABLE_RESERVATIONS_FOR_MONTH:
		'주중의 예약가능횟수는 최대 n회 입니다.<br /> 예약불가 합니다.',
	EXCEED_COUNT_OF_WEEKEND_AVAILABLE_RESERVATIONS_FOR_MONTH:
		'주말의 예약가능횟수는 최대 n회 입니다.<br /> 예약불가 합니다.',
	EXCEED_COUNT_OF_AVAILABLE_RESERVATIONS_FOR_MONTH:
		'월 예약가능횟수는 최대 n회 입니다.<br /> 예약불가 합니다.',
	EXCEED_COUNT_OF_WEEKDAY_TRANS_RESERVATIONS_FOR_MONTH:
		'주중 위임 예약 가능횟수는<br /> 최대 %d회 입니다. 확인해 주십시오.',
	EXCEED_COUNT_OF_WEEKEND_TRANS_RESERVATIONS_FOR_MONTH:
		'주말 위임 예약 가능횟수는<br /> 최대 %d회 입니다. 확인해 주십시오.',
	EXCEED_COUNT_OF_TRANS_RESERVATIONS_FOR_MONTH:
		'월 위임 예약 가능횟수는<br /> 최대 %d회 입니다. 확인해 주십시오.',
	EXCEED_COUNT_OF_AVAILABLE_RESERVATION_CANCEL_FOR_MONTH:
		'월 예약 취소 가능 횟수가<br /> 초과되었습니다.',
	ZERO_COUNT_OF_WEEKDAY_AVAILABLE_RESERVATIONS_FOR_MONTH:
		'주중 예약 가능 횟수는 0회입니다.<br />(예치금 입금 확인 후 예약 가능합니다.)',
	ZERO_COUNT_OF_WEEKEND_AVAILABLE_RESERVATIONS_FOR_MONTH:
		'주중 예약 가능 횟수는 0회입니다.<br />(예치금 입금 확인 후 예약 가능합니다.)',
	ZERO_COUNT_OF_AVAILABLE_RESERVATIONS_FOR_MONTH:
		'월 예약 가능 횟수는 0회입니다.<br />(예치금 입금 확인 후 예약 가능합니다.)',
	ZERO_COUNT_OF_AVAILABLE_TRANS_RESERVATIONS_FOR_MONTH:
		'월 위임 예약 가능 횟수는 0회입니다.',
	ZERO_COUNT_OF_AVAILABLE_RESERVATIONS_CANCEL_FOR_MONTH:
		'월 예약 취소 가능 횟수는 0회입니다.',
	ALREADY_CHECK_IN: '내장 완료',
	ALREADY_CHECK_OUT: '체크아웃 완료',
	NOT_RESERVATION_TIME: '예약 불가능한 시간',
	REQUIRED_HIGHER_MEMBER_GRADE:
		'예치금 회원 가입 후 <br />예약서비스 이용이 가능합니다.',
	COMPLETE_DEPOSIT_INCOME:
		'입금처리가 완료되어 수정이 불가합니다. <br />골프장에 문의하시길 바랍니다.',
	COMPLETE_DEPOSIT_RETURN:
		'반환 수정이 가능한 예치금이<br /> 존재하지 않습니다. <br />골프장에 문의하시길 바랍니다.',
	ALREADY_RESERVATION_CONFIRM: '이미 예약확정되어서<br /> 취소할 수 없습니다.',
	NOT_RESERVATION_MOVE_DATE: '예약 변경 가능일자가 아닙니다.',
	NOT_TRANS_RESERVATION_MOVE_DATE: '위임 변경 가능일자가 아닙니다.',
	NOT_RESERVATION_MOVE_TIME: '예약 변경 가능시간이 아닙니다.',
	NOT_RESERVATION_CANCEL_DATE:
		'취소 가능한 일자가 아닙니다. <br />프론트로 문의바랍니다. <br />031-962-0101',
	NOT_WAITING_RESERVATION_CANCEL_DATE: '대기 예약 취소 가능일자가 아닙니다.',
	NOT_LOTTERY_RESERVATION_CANCEL_DATE: '추첨 예약 취소 가능일자가 아닙니다.',
	ALREADY_WAITING_RESERVATION_EQUAL_DATE:
		'이미 같은 날짜에 등록된<br /> 대기예약건이 존재합니다.',
	ALREADY_LOTTERY_RESERVATION_EQUAL_DATE:
		'이미 같은 날짜에 등록된<br /> 추첨예약건이 존재합니다.',
	ALREADY_EXIST_LOTTERY_RESERVATION:
		'추첨 예약은 한 주에 x건만<br /> 신청 가능합니다.<br /> 이미 0000년 00월 00일 신청 하셨습니다.',
	NOT_OPEN_LOTTERY_RESERVATION: '추첨예약 오픈되지 않음',
	NOT_EXIST_LOTTERY_RESERVATION_TIME: '추첨시간대에 해당하는 타임이 없음',
	DO_NOT_HAVE_LOTTERY_RESERVATION_AUTH: '추첨예약 권한 없음',
	CANNOT_CHOOSE_VISIT_COUNT2: '2인팀 선택 불가',
	CANNOT_REGISTERED_COMPANION_HAS_RESERVATIONS_PENALTY:
		'위약이 존재하여<br /> 동반자 등록을 하실 수 없습니다.',
	NOT_FOUND_RESERVATION: '예약을 확인해주세요',
	CANCELED_RESERVATION: '취소된 예약건 입니다.',
	CANT_CHECK_IN_BOOKER: '예약자가 체크인 가능한<br /> 타임이 아닙니다',
	INVALID_URL: '잘못된 URL 입니다.',
	REQUIRED_BOOKER_CERTIFICATE: '예약자 인증번호를 입력해주세요',
	NOT_MATCH_HP: '핸드폰번호를 잘못입력하셨습니다.',
	UNAVAILABLE_ECOFRONT: "무인체크인 사용이 불가능합니다.'",
	CHECK_IN_ON_THR_DAY_ONLY: 'CHECK-IN은<br /> 당일에만 가능 합니다.',
	PAST_CHECK_IN_TIME: 'CHECK-IN 할 수 있는<br /> 시간이 지났습니다.',
	NOT_YET_CHECK_IN_TIME: 'CHECK-IN 할 수 있는<br /> 시간이 되지 않았습니다.',
	CANNOT_REGISTERED_COMPANION:
		'동반자 등록 할 수 있는<br /> 시간이 지났습니다.',
	CANNOT_DELETED_COMPANION: '동반자 삭제 할 수 있는<br /> 시간이 지났습니다.',
	OUT_OF_GPS_DISTANCE: 'CHECK-IN 허용 거리가 아닙니다.',
	UNAVAILABLE_LOCKER: '사용할 수 있는 락카가 존재하지 않습니다.',
	DUPLICATED_HP_COMPANION: '이미 등록된 핸드폰 번호입니다.',
	DUPLICATED_HP_BOOKER: '예약자 핸드폰 번호는 입력할 수 없습니다.',
	CANNOT_CHECK_IN: '체크인하실 수 없습니다.<br /> 프론트에 문의바랍니다.',
	FULL_VISITORS_OF_TEAM: '체크인 가능 인원수를 초과하였습니다.',
	FULL_COMPANIONS_OF_RESERVATION: '동반자 등록 가능 인원수를 초과하였습니다.',
	REQUIRED_HP_FOR_ECOFRONT:
		'무인체크인 서비스를 시행하고 있습니다.<br /> 핸드폰 번호는 필수 입니다.',
	CANNOT_CHANGE_VISIT_COUNT_MISMATCH:
		'동반자 x명이 입력되어있습니다.<br /> x인 타임으로 이동할 수 없습니다.',
	PART_3_RESERVATION: '3부는 선결제 후 입장가능합니다.',
	ONLY_PART_3: '예치금회원이 아니기 때문에<br /> 3부 타임만 예약가능합니다.',
	LOCAL_PEOPLE: '지역민 할인은<br /> 프론트 본인 확인후 가능합니다.',
	PART_3_RESERVATION_AND_LOCAL_PEOPLE:
		'3부는 선결제 후 입장가능합니다.<br /> + 지역민 할인은 프론트 본인 확인후 가능합니다.',
	PENALTY_VISIT: '내장 위약이 존재합니다.<br /> 내장이 불가능합니다.',
	REQUIRED_PROVIDE_INFO:
		'개인정보 수집 및 이용 동의 하셔야<br /> 모바일 셀프체크인을 이용하실 수 있습니다.',
	NOT_FOUND_COMPANION: '동반자를 찾을 수 없습니다.',
	CANNOT_USE_SELF_CHECKIN: '모바일 셀프체크인 이용 불가',
	NOT_FOUND_CHECK_IN: '내장을 확인해주세요',
	DO_NOT_HAVE_AUTH: '수정 권한이 없습니다.',
	ALREADY_MANAGEMENT_VOC: '이미 답변 완료된 고객의 소리입니다.',
	NOT_FOUND_MEMBER: '회원을 찾을 수 없습니다.',
	NOT_FOUND_MEMBERSHIP_ID: '회원권 정보를 찾을 수 없습니다.',
	NOT_FOUND_WEB_MEMBERSHIP: '웹회원 회원권 정보를 찾을 수 없습니다.',
	CANNOT_WITHDRAW_HAS_MEMBERSHIP:
		'웹회원 회원권이 아닐 경우<br /> 회원 탈퇴를 하실 수 없습니다.',
	NOT_FOUND_POST: '게시글이 존재하지 않습니다.',
	NOT_FOUND_COMMENT: '덧글이 존재하지 않습니다.',
}