export const UserApiEndpoint = {
  fetchUserInfo: '/users', // 회원정보조회
  fetchUserDetail: '/users/info', // 회원 이름, 전화번호 조회
  updateRealtimeReservationStatus: '',
  cancelReservation: '',
  signUp: '/users',
  updateUserInfo: '/users', // 회원정보수정
  withdrawal: '/users', // 회원탈퇴
  checkUserIdDuplicate: '/users/check-id', // id 중복체크
  validExistMember: '/users/member/is-exist', // 웹 아이디 기가입 체크
};
