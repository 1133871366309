export const AuthApiEndpoint = {
  signIn: '/auth/issue',
  signInAdmin: '/auth/issue-admin',
  signOut: '/auth/withdraw',
  checkIdDuplicate: '/users/check-id', // 아이디 중복확인
  findId: '/users/find-id', // 아이디 찾기
  sendVerificationCode: '/hp/verify-number', // 휴대폰 인증번호발송
  verifyVerificationCode: '/hp/verify-number/confirm', // 휴대폰 인증번호 확인
  verifyVerificationCodeForMember: '/hp/verify-number/confirm/member', // 휴대폰 인증번호 확인-정회원
  // requestPasswordVerificationCode: '/users/identification', // 신규비밀번호 생성 인증 시작
  // checkPasswordVerificationCode: '/users/verify-number', // 신규비밀번호 생성 인증 확인
  updatePassword: '/users/password', // 비밀번호 찾기에서 비밀번호 변경
  updatePasswordNow: '/users/password-user', // 로그인 후 일정기간 지났을 경우 비밀번호 변경하시겠습니까? 라고 했을 때 바로 비밀번호 변경하는 경우
  updatePasswordNext: '/users/password-next', // 로그인 후 일정기간 지났을 경우 비밀번호 변경하시겠습니까? 라고 했을 때 다음에 변경하는 경우
  niceCertificationEnc: '/auth/realNameCertificate/encKey', // 나이스 인증 준비
  niceCertificationDec: '/auth/realNameCertificate/decInfo', // 나이스 인증 확인
};
