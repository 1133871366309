import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosRequestHeaders,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import { HttpInterface } from '@core/http';
import {
  useAlertStore,
  useCommonStore,
  useSettingStore,
  useUserStore,
} from '@stores';
import { useRouter } from 'vue-router';
import { ROUTE_NAME, ERROR_MESSAGES } from '@constants';
import {} from '@constants';

interface ExtendAxiosInstance {
  delete<T = any, R = AxiosResponse<T>, D = any>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig<D>
  ): Promise<R>;
}
type CustomAxiosInstance = AxiosInstance & ExtendAxiosInstance;
export class AxiosAdapter implements HttpInterface {
  private readonly axiosInstance: CustomAxiosInstance;
  private alertStore = useAlertStore();
  private commonStore = useCommonStore();
  private userStore = useUserStore();
  private router = useRouter();
  private settingStore = useSettingStore();

  constructor(config?: AxiosRequestConfig) {
    // create axios instance
    this.axiosInstance = axios.create({
      baseURL: import.meta.env.VITE_API_BASE_URL || 'http://localhost:3000',
      headers: {
        'Content-Type': 'application/json',
      },
      timeout: 10000, // 10초
      ...config,
    }) as CustomAxiosInstance;
    this.handleRequest = this.handleRequest.bind(this);
    this.handleRequestError = this.handleRequestError.bind(this);
    this.handleResponse = this.handleResponse.bind(this);
    this.handleResponseError = this.handleResponseError.bind(this);

    this.axiosInstance.interceptors.request.use(
      this.handleRequest,
      this.handleRequestError
    );
    this.axiosInstance.interceptors.response.use(
      this.handleResponse,
      this.handleResponseError
    );
  }

  async handle400Error(errorMessage: string, errorCode: string) {
    console.error('400 Bad Request Error');
    if (errorCode === 'NO_PASSWORD') {
      await this.alertStore.showConfirmDialog(errorMessage);
      this.router.push({ name: ROUTE_NAME.NewPasswordAccount });
    } else {
      await this.alertStore.showAlertDialog(errorMessage);
    }
  }
  async handle401Error(errorMessage: string) {
    console.error('401 Unauthorized Error');
    await this.alertStore.showAlertDialog(errorMessage);
    this.commonStore.clearAccessToken();
    this.commonStore.setSigninOpen(true);
  }
  async handle403Error(errorMessage: string) {
    console.error('403 Forbidden Error');
    await this.alertStore.showAlertDialog(errorMessage);
  }
  async handle404Error(errorMessage: string) {
    console.error('404 Not Found Error');
    await this.alertStore.showAlertDialog(errorMessage);
  }
  async handle500Error(errorMessage: string) {
    console.error('500 Internal Server Error');
    await this.alertStore.showAlertDialog(errorMessage);
  }
  async handle503Error(error: AxiosResponse) {
    const data = error.data;
    if (!Object.keys(data).length || typeof data === 'string') {
      this.router.push('/repairs');
    } else {
      const { description, excuse, title, start, end } = data;
      const query: {
        [key: string]: string;
      } = {};
      if (description) {
        query['description'] = description;
      }
      if (excuse) {
        query['excuse'] = excuse;
      }
      if (title) {
        query['title'] = title;
      }
      if (start) {
        query['start'] = start;
      }
      if (end) {
        query['end'] = end;
      }
      this.router.push({
        path: '/repairs',
        query,
      });
    }
  }
  async handleErrorResponse(
    error: AxiosError<{
      data:
        | string
        | {
        description: string;
        excuse: string;
        title: string;
        start: string;
        end: string;
      };
      status: string;
    }>
  ) {
    if (axios.isAxiosError(error) && error.response) {
      // 서버에서 응답을 받았으나 오류가 발생한 경우
      const errorStatus = error.response.status; // HTTP 상태 코드 400, 500 등
      const errorCode = error.response.data.status; // 서버에서 정의한 에러 코드
      const errorData = error.response.data.data; // 서버에서 정의한 에러메시지
      let errorMessage =
        ERROR_MESSAGES[errorCode as keyof typeof ERROR_MESSAGES];
      if (errorData && errorMessage.match(':v')?.length) {
        if (typeof errorData === 'string') {
          errorMessage = errorMessage.replace(':v', errorData);
        }
      }

      if (error.config?.url?.indexOf('/weathers') !== -1) {
        return;
      }

      switch (errorStatus) {
        case 400:
          await this.handle400Error(errorMessage, errorCode);
          break;
        case 401:
          await this.handle401Error(errorMessage);
          break;
        case 403:
          await this.handle403Error(errorMessage);
          break;
        case 404:
          await this.handle404Error(errorMessage);
          break;
        case 500:
          await this.handle500Error(errorMessage);
          break;
        case 503:
          await this.handle503Error(error.response);
          break;
        default:
          await this.alertStore.showAlertDialog(`${errorCode}`);
      }
    } else if (error.request) {
      if (error.config?.url?.indexOf('/weathers') !== -1) {
        return;
      }
      // 요청은 보냈지만 서버로부터 응답을 받지 못한 경우
      console.error('No response received');
      await this.alertStore.showAlertDialog(
        '응답이 없습니다. 다시 시도해주세요.'
      );
    } else {
      // 요청 자체에 문제가 있는 경우
      console.error('Error setting up request');
      await this.alertStore.showAlertDialog('요청에 문제가 있습니다.');
    }
  }
  handleRequest(
    config: InternalAxiosRequestConfig
  ): InternalAxiosRequestConfig {
    const token = this.commonStore.getSessionStorage('token');
    const membershipId =
      this.userStore.grant === 'WEB_USER'
        ? this.commonStore.getSessionStorage('membershipId')
        : this.userStore.grant === 'WEB_ADMIN'
          ? 'AdminYJ'
          : null;
    if (token) {
      config.headers = (config.headers as AxiosRequestHeaders) || {};
      config.headers.Authorization = `${membershipId} ${token}`;
    }
    this.commonStore.setIsLoading(true);
    return config;
  }
  handleRequestError(error: AxiosError): Promise<AxiosError> {
    this.commonStore.setIsLoading(false);
    return Promise.reject(error);
  }
  handleResponse(response: AxiosResponse): AxiosResponse {
    this.settingStore.setPreferences(response.headers);
    this.commonStore.setIsLoading(false);
    return response.data;
  }
  async handleResponseError(
    error: AxiosError<{ data: string; status: string }>
  ): Promise<AxiosError> {
    this.commonStore.setIsLoading(false);

    await this.handleErrorResponse(error);
    return Promise.reject(error);
  }
  /**
   * interface는 클래스의 공개된 api를 정의하는데 사용되며
   * 클래스 내부에서 사용하는 protected, private 메서드는 interface에 정의하지 않는다.
   */
  replacedUrl = (
    urlOrigin: string,
    pathParams: Record<string, string | number>
  ): string =>
    Object.entries(pathParams).reduce(
      (accUrl, [key, value]) => accUrl.replace(`:${key}`, `${value}`),
      urlOrigin
    );

  async get<T>(
    url: string,
    params?: RequestParams,
    config?: HttpRequestConfig
  ): Promise<HttpResponse<T>> {
    const { data, status, statusText, headers } =
      await this.axiosInstance.get<T>(url, {
        params,
        ...config,
      });
    return {
      data,
      status,
      statusText,
      headers,
    };
  }

  async post<T>(
    url: string,
    body?: RequestBody,
    config?: HttpRequestConfig
  ): Promise<HttpResponse<T>> {
    const { data, status, statusText, headers } =
      await this.axiosInstance.post<T>(url, body, config);
    return {
      data,
      status,
      statusText,
      headers,
    };
  }

  async put<T>(
    url: string,
    body?: RequestBody,
    config?: HttpRequestConfig
  ): Promise<HttpResponse<T>> {
    const { data, status, statusText, headers } =
      await this.axiosInstance.put<T>(url, body, config);
    return {
      data,
      status,
      statusText,
      headers,
    };
  }

  async patch<T>(
    url: string,
    body?: RequestBody,
    config?: HttpRequestConfig
  ): Promise<HttpResponse<T>> {
    const { data, status, statusText, headers } =
      await this.axiosInstance.patch<T>(url, body, config);
    return {
      data,
      status,
      statusText,
      headers,
    };
  }

  async delete<T>(
    url: string,
    // body?: RequestBody,
    config?: HttpRequestConfig
  ): Promise<HttpResponse<T>> {
    const { data, status, statusText, headers } =
      await this.axiosInstance.delete<T>(url, config);
    return {
      data,
      status,
      statusText,
      headers,
    };
  }
}
