<template>
  <header :class="headerClass">
    <div class="top-area">
      <router-link :to="{ name: ROUTE_NAME.Main }">
        <img
          v-if="(isMain && !isScroll) || isMenuClicked"
          src="@assets/img/olympic-logo-mobile-white.svg"
          alt="logo"
        />
        <img
          v-if="(!isMain || isScroll) && !isMenuClicked"
          src="@assets/img/mobile/logo_col.svg"
          alt="logo"
        />
      </router-link>
      <div class="right-area">
        <button v-if="!commonStore.isSignIn" @click="handleSignIn">
          <img
            v-if="(isMain && !isScroll) || isMenuClicked"
            src="@assets/img/icon-human.svg"
            alt="사람아이콘"
          />
          <img v-else src="@assets/img/icon-human-black.svg" alt="사람아이콘" />
        </button>
        <button v-if="!isMenuClicked" @click="handleToggleMenu(true)">
          <img
            v-if="isMain && !isScroll"
            src="@assets/img/icon-hamburger.svg"
            alt="메뉴아이콘"
          />
          <img
            v-else
            src="@assets/img/icon-hamburger-black.svg"
            alt="메뉴아이콘"
          />
        </button>
        <button v-if="isMenuClicked" @click="handleToggleMenu(false)">
          <img src="@assets/img/icon-close.svg" alt="닫기버튼" />
        </button>
      </div>
    </div>
    <div v-if="isMenuClicked" class="menu-area">
      <div class="menu-login">
        <p v-if="!commonStore.isSignIn">
          로그인하세요.<span class="sub"
            >회원이 되시면 다양한 혜택을 누릴 수 있습니다.</span
          >
        </p>
        <p v-if="commonStore.isSignIn">
          <span class="bold">{{ userStore.username }}</span
          >님<span class="sub">올림픽컨트리클럽에 오신것을 환영합니다.</span>
        </p>
        <div class="button-wrapper">
          <div v-if="!commonStore.isSignIn">
            <a role="button" class="left-button" @click="handleSignIn"
              >로그인</a
            >
            <router-link :to="{ name: ROUTE_NAME.Signup }"
              >회원가입</router-link
            >
          </div>
          <div v-if="commonStore.isSignIn">
            <a role="button" class="left-button" @click="handleSignOut"
              >로그아웃</a
            >
            <router-link :to="{ name: ROUTE_NAME.UserInfoModify }">
              회원정보변경
            </router-link>
          </div>
        </div>
      </div>
      <div class="menu">
        <ul class="menu-all">
          <li
            v-for="(gnb, index) in gnbList2"
            :key="index"
            class="depth-01"
            :class="[{ on: index === activeLnb }]"
          >
            <div class="gnb-item-wrapper" @click="handleToggleLnb(index)">
              <span class="title">
                {{ gnb.title }}
              </span>
              <img
                v-if="index === activeLnb"
                class="drop-down"
                src="@assets/img/icon-mobile-menu-opened.svg"
                alt="메뉴열렸을 때 아이콘"
              />
              <img
                v-else
                class="drop-down"
                src="@assets/img/icon-mobile-menu-closed.svg"
                alt="메뉴닫혔을 때 아이콘"
              />
            </div>
            <ul v-if="index === activeLnb" class="lnb depth-02">
              <li
                v-for="(item, index) in gnb.children"
                :key="index"
                @click="handleRoute(item)"
              >
                {{ item.title }}
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </header>
  <!--  <div>-->
  <!--    <div-->
  <!--      id="header"-->
  <!--      :class="{-->
  <!--        active: isScroll || !isMain,-->
  <!--      }"-->
  <!--    >-->
  <!--      <div class="header-area">-->
  <!--        <router-link to="/" title="메인" class="logo">-->
  <!--          <img-->
  <!--            v-if="isScroll || !isMain"-->
  <!--            src="@assets/img/mobile/logo_col.svg"-->
  <!--            alt="logo"-->
  <!--          />-->
  <!--          <img v-else src="@assets/img/mobile/logo_wht.svg" alt="logo" />-->
  <!--        </router-link>-->
  <!--        <ul class="login-box">-->
  <!--          <li v-if="!commonStore.isSignIn">-->
  <!--            <button @click="handleSignIn">-->
  <!--              <img-->
  <!--                src="/src/infrastructure/assets/img/mobile/login_icon.svg"-->
  <!--                alt="로그인"-->
  <!--                class="login"-->
  <!--              />-->
  <!--            </button>-->
  <!--          </li>-->
  <!--          <li @click="handleToggleMenu(true)">-->
  <!--            <img-->
  <!--              src="/src/infrastructure/assets/img/mobile/menu_icon.svg"-->
  <!--              alt="메뉴"-->
  <!--            />-->
  <!--          </li>-->
  <!--        </ul>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--    <div v-if="isMenuClicked" class="menu-area">-->
  <!--      <div class="menu-con">-->
  <!--        <div class="menu-top">-->
  <!--          <router-link to="/" class="logo"></router-link>-->
  <!--          <button title="닫기" @click="handleToggleMenu(false)">-->
  <!--            <img-->
  <!--              src="/src/infrastructure/assets/img/mobile/x_icon.svg"-->
  <!--              alt="닫기"-->
  <!--            />-->
  <!--          </button>-->
  <!--        </div>-->
  <!--        <div class="menu-login">-->
  <!--          <p v-if="!commonStore.isSignIn">-->
  <!--            로그인하세요.<span class="sub"-->
  <!--              >회원이 되시면 다양한 혜택을 누릴 수 있습니다.</span-->
  <!--            >-->
  <!--          </p>-->
  <!--          <p v-if="commonStore.isSignIn">-->
  <!--            <span class="bold">{{ userStore.username }}</span-->
  <!--            >님<span class="sub">올림픽컨트리클럽에 오신것을 환영합니다.</span>-->
  <!--          </p>-->
  <!--          <div class="login-btn">-->
  <!--            <div v-if="!commonStore.isSignIn">-->
  <!--              <a role="button" class="on" @click="handleSignIn">로그인</a>-->
  <!--              <router-link :to="{ name: ROUTE_NAME.Signup }"-->
  <!--                >회원가입</router-link-->
  <!--              >-->
  <!--            </div>-->
  <!--            <div v-if="commonStore.isSignIn">-->
  <!--              <a role="button" class="on" @click="handleSignOut">로그아웃</a>-->
  <!--              <router-link :to="{ name: ROUTE_NAME.UserInfoModify }">-->
  <!--                회원정보변경-->
  <!--              </router-link>-->
  <!--            </div>-->
  <!--          </div>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--      &lt;!&ndash; 메뉴 리스트 &ndash;&gt;-->
  <!--      <div class="overflow-y-scroll">-->
  <!--        <ul class="menu-all">-->
  <!--          <li-->
  <!--            v-for="(gnb, index) in gnbList2"-->
  <!--            :key="index"-->
  <!--            class="depth-01"-->
  <!--            :class="[{ on: index === activeLnb }]"-->
  <!--          >-->
  <!--            <div class="gnb-item-wrapper" @click="handleToggleLnb(index)">-->
  <!--              <span class="title">-->
  <!--                {{ gnb.title }}-->
  <!--              </span>-->
  <!--              <img-->
  <!--                v-if="index === activeLnb"-->
  <!--                class="drop-down"-->
  <!--                src="@assets/img/mobile/less_icon.svg"-->
  <!--                alt="메뉴열렸을 때 아이콘"-->
  <!--              />-->
  <!--              <img-->
  <!--                v-else-->
  <!--                class="drop-down"-->
  <!--                src="@assets/img/mobile/plus_icon.svg"-->
  <!--                alt="메뉴닫혔을 때 아이콘"-->
  <!--              />-->
  <!--            </div>-->
  <!--            <ul v-if="index === activeLnb" class="lnb depth-02">-->
  <!--              <li-->
  <!--                v-for="(item, index) in gnb.children"-->
  <!--                :key="index"-->
  <!--                @click="handleRoute(item)"-->
  <!--              >-->
  <!--                {{ item.title }}-->
  <!--              </li>-->
  <!--            </ul>-->
  <!--          </li>-->
  <!--        </ul>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </div>-->
</template>
<script setup lang="ts">
import { ref, watch, computed } from 'vue';
import { useCommonStore, useUserStore } from '@stores';
import { ROUTE_NAME } from '@constants';
import { useRouter } from 'vue-router';
import { useSignOut, useScroll, useIsMain, useScrollLock } from '@composables';

interface LnbItem {
  title: string;
  name: string;
  isAdmin?: boolean;
}
interface GnbItem {
  title: string;
  name: string;
  children: LnbItem[];
}

const commonStore = useCommonStore();
const userStore = useUserStore();
const { handleSignOut: signOut } = useSignOut();
const { isScroll } = useScroll();
const { isMain } = useIsMain();
const { lockScroll, unLockScroll } = useScrollLock();
const { currentRoute, push } = useRouter();
const isMenuClicked = ref<boolean>(false);
const activeLnb = ref<number>(0);

const headerClass = computed(() => {
  return {
    main: isMain.value,
    scroll: isScroll.value,
    'menu-open': isMenuClicked.value,
  };
});

const lnbList = ref<LnbItem[][]>([
  [
    {
      title: '예약하기',
      name: ROUTE_NAME.Reservation,
    },
    {
      title: '예약안내',
      name: ROUTE_NAME.ReservationInformation,
    },
    {
      title: '예약확인',
      name: ROUTE_NAME.ReservationStatus,
    },
  ],
  [
    {
      title: '클럽소개',
      name: ROUTE_NAME.ClubIntroduction,
    },
    {
      title: '오시는 길',
      name: ROUTE_NAME.Location,
    },
    {
      title: 'CI소개',
      name: ROUTE_NAME.ClubCi,
    },
  ],
  [
    {
      title: '이용방법',
      name: ROUTE_NAME.Guide,
    },
    {
      title: '요금안내',
      name: ROUTE_NAME.FeeInformation,
    },
    {
      title: '로컬룰',
      name: ROUTE_NAME.RuleInformation,
    },
  ],
  [
    {
      title: '코스공략',
      name: ROUTE_NAME.CourseStrategy,
    },
  ],
  [
    {
      title: '클럽하우스',
      name: ROUTE_NAME.ClubHouse,
    },
    {
      title: '스타트하우스',
      name: ROUTE_NAME.StartHouse,
    },
    {
      title: '토방',
      name: ROUTE_NAME.OndolRoom,
    },
    {
      title: '라이트시설',
      name: ROUTE_NAME.Light,
    },
  ],
  [
    {
      title: '공지사항',
      name: ROUTE_NAME.Notice,
    },
    {
      title: '회원가입',
      name: ROUTE_NAME.Signup,
    },
    {
      title: '아이디 및 비밀번호 찾기',
      name: ROUTE_NAME.FindId,
    },
    {
      title: '배너관리',
      name: ROUTE_NAME.Banner,
      isAdmin: true,
    },
  ],
]);
const lnbList2 = ref<LnbItem[][]>([]);
const gnbList2 = ref<GnbItem[]>();
const gnbList = [
  {
    title: '예약서비스',
    name: ROUTE_NAME.ReservationService,
  },
  {
    title: '클럽소개',
    name: ROUTE_NAME.ClubIntroduction,
  },
  {
    title: '이용안내',
    name: ROUTE_NAME.UseInformation,
  },
  {
    title: '코스안내',
    name: ROUTE_NAME.CourseInformation,
  },
  {
    title: '시설안내',
    name: ROUTE_NAME.Facilities,
  },
  {
    title: '고객지원',
    name: ROUTE_NAME.Community,
  },
];

const handleToggleLnb = (index: number) => {
  activeLnb.value = index;
  // if (activeLnb.value === index) {
  //   activeLnb.value = -1;
  // } else {
  //   activeLnb.value = index;
  // }
};
const handleToggleMenu = (value: boolean) => {
  isMenuClicked.value = value;
  isMenuClicked.value ? lockScroll() : unLockScroll();
};
const handleSignIn = async () => {
  handleToggleMenu(false);
  commonStore.setSigninOpen(true);
};

const handleSignOut = async () => {
  await signOut();
  await push({ name: ROUTE_NAME.Main });
};
const handleRoute = (item: { title: string; name: string }) => {
  // unLockScroll();
  // isMenuClicked.value = false;
  handleToggleMenu(false);
  currentRoute.value.name !== item.name && push({ name: item.name });
};

watch(currentRoute, () => {
  handleToggleMenu(false);
});
watch(
  () => userStore.grant,
  (userGrant) => {
    lnbList2.value = lnbList.value.map((group) =>
      group.filter((item) => !item.isAdmin || userGrant === 'WEB_ADMIN')
    );
    gnbList2.value = gnbList.map((gnb, index) => ({
      ...gnb,
      children: lnbList2.value[index],
    }));
  },
  {
    immediate: true,
  }
);
</script>

<style scoped lang="scss">
header {
  @apply fixed top-0 left-0 w-full z-50 bg-white overflow-y-scroll;
  transition: ease-in 0.1s all;
  &.main,
  &.scroll {
    @apply bg-transparent;
  }
  &.scroll {
    @apply bg-white;
  }
  &.menu-open {
    @apply h-screen;
    background:
      url('@assets/img/menu-bg.jpg') no-repeat -800px bottom,
      darken(rgba(8, 88, 73, 1), 10);
    background-size: cover;
    background-blend-mode: overlay;
    .top-area {
      background-color: #085849;
    }
  }
  .top-area {
    @apply flex justify-between items-center py-10 px-20;
    //background-color: #085849;
    border-bottom: 1px solid #eee;
    .right-area {
      @apply flex justify-end gap-x-10;
    }
  }
  .menu-area {
    .menu-login {
      @apply px-20;
      padding-top: 38px;
      background-color: #085849;
      padding-bottom: 30px;
      p {
        font-size: 1.25em;
        font-weight: 300;
        color: #fff;
        span.sub {
          font-size: 14px;
          font-weight: 200;
          display: block;
          padding-top: 4px;
        }
        span.bold {
          font-weight: 500;
        }
      }
      .button-wrapper {
        display: flex;
        color: #005aa9;
        margin: 16px auto 0;
        a {
          height: 32px;
          border-radius: 4px;
          font-size: 14px;
          font-weight: 500;
          text-align: center;
          color: #fff;
          background-color: #20685b;
          padding: 5px 30px;
          margin-right: 8px;
          border: 1px solid #20685b;
          &.left-button {
            color: #ccc;
            background-color: rgba(255, 255, 255, 0);
            border: 1px solid #ccc;
          }
        }
      }
    }
    .menu {
      height: calc(100vh - 227px);
      overflow: scroll;
      .menu-all {
        @apply mx-auto w-9/10;
        padding-bottom: 150px;
      }
      .gnb-item-wrapper {
        @apply flex justify-between;
        padding: 16px 0;
      }
      li {
        &.depth-01 {
          font-size: 18px;
          font-weight: 300;
          color: #ccc;
        }
        &.on {
          color: #fff;
          font-weight: 500;
        }
      }
      ul.depth-02 li {
        font-size: 16px;
        padding: 14px 0 9px 15px;
        font-weight: 300;
      }
      //li.depth-01 {
      //  font-size: 18px;
      //  font-weight: 300;
      //  color: #ccc;
      //  a {
      //    padding: 16px 0;
      //    display: flex;
      //    justify-content: space-between;
      //    align-items: center;
      //  }
      //}
      //li.depth-02 {
      //  font-size: 16px;
      //  padding: 14px 0 9px 15px;
      //  font-weight: 300;
      //  a {
      //    padding: 0;
      //  }
      //}
    }
  }
}
/* HEADER */
//#header {
//  width: 100%;
//  height: 60px;
//  position: fixed;
//  top: 0;
//  z-index: 30;
//}
//
//.header-area {
//  width: 90%;
//  height: 100%;
//  border-bottom: 1px solid #fff;
//  margin: 0 auto;
//  position: relative;
//}
//
//.header-area .logo {
//  width: 42px;
//  height: 42px;
//  position: absolute;
//  left: 0;
//  top: 50%;
//  transform: translateY(-50%);
//  //background: url('src/infrastructure/assets/img/mobile/logo_wht.svg') no-repeat
//  //  center center / 42px;
//}
//
//.login-box {
//  position: absolute;
//  right: 0;
//  top: 50%;
//  transform: translateY(-50%);
//  display: flex;
//  align-items: center;
//}
//
//.login-box img.login {
//  height: 28px;
//  padding-top: 2px;
//  margin-right: 5px;
//}
//
///* HEADER Active */
//#header.active {
//  background-color: #fff;
//}
//
//#header.active .header-area {
//  border-bottom: 1px solid #eeeeee;
//}
//
//#header.active .header-area .logo {
//  width: 42px;
//  height: 42px;
//  position: absolute;
//  left: 0;
//  top: 50%;
//  transform: translateY(-50%);
//  //background: url('src/infrastructure/assets/img/mobile/logo_col.svg') no-repeat
//  //  center center / 42px;
//}
//
//#header.active .login-box img {
//  filter: invert(1);
//}
//
///* MENU */
//.menu-area {
//  @apply fixed;
//  width: 100%;
//  height: 100vh;
//  background-color: rgba(0, 107, 87, 0.9);
//  z-index: 9;
//  position: relative;
//  top: 0;
//}
//
//.menu-area .menu-con {
//  width: 100%;
//  height: 220px;
//  background-color: #085849;
//}
//
//.menu-area .menu-con .menu-top {
//  width: 90%;
//  height: 60px;
//  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
//  margin: 0 auto;
//  display: flex;
//  align-items: center;
//  justify-content: space-between;
//}
//
//.menu-area .menu-con .menu-top .logo {
//  width: 42px;
//  height: 42px;
//  background: url('src/infrastructure/assets/img/mobile/logo_wht.svg') no-repeat
//    center center / 42px;
//}
//
//.menu-area .menu-con .menu-login {
//  width: 90%;
//  margin: 0 auto;
//  padding-top: 38px;
//}
//
//.menu-area .menu-con .menu-login p {
//  font-size: 1.25em;
//  font-weight: 300;
//  color: #fff;
//}
//
//.menu-area .menu-con .menu-login p span.sub {
//  font-size: 14px;
//  font-weight: 200;
//  display: block;
//  padding-top: 4px;
//}
//
//.menu-area .menu-con .menu-login p span.bold {
//  font-weight: 500;
//}
//
//.menu-area .menu-login .login-btn {
//  display: flex;
//  color: #005aa9;
//  margin: 16px auto 0;
//}
//
//.menu-area .menu-login .login-btn a {
//  height: 32px;
//  border-radius: 4px;
//  font-size: 14px;
//  font-weight: 500;
//  text-align: center;
//  color: #fff;
//  background-color: #20685b;
//  padding: 5px 30px;
//  margin-right: 8px;
//  border: 1px solid #20685b;
//}
//
//.menu-area .menu-login .login-btn a.left-button {
//  color: #ccc;
//  background-color: rgba(255, 255, 255, 0);
//  border: 1px solid #ccc;
//}
//
///* MENU List */
//.menu-area .menu-all {
//  width: 90%;
//  margin: 0 auto;
//}
//.gnb-item-wrapper {
//  @apply flex justify-between;
//  padding: 16px 0;
//}
//.menu-area .menu-all li.depth-01 {
//  font-size: 18px;
//  font-weight: 300;
//  color: #ccc;
//}
//
//.menu-area .menu-all li.depth-01 a {
//  padding: 16px 0;
//  display: flex;
//  justify-content: space-between;
//  align-items: center;
//}
//
//.menu-area .menu-all li ul.depth-02 li {
//  font-size: 16px;
//  padding: 14px 0 9px 15px;
//  font-weight: 300;
//}
//
//.menu-area .menu-all li ul.depth-02 li a {
//  padding: 0;
//}
//
//.menu-area .menu-all li.on {
//  color: #fff;
//  font-weight: 500;
//}
//
//.pop-close {
//  background: rgba(255, 255, 255, 0.11);
//  width: 48px;
//  height: 48px;
//  border-radius: 50%;
//  padding: 16px;
//  margin: 16px auto 0;
//}
</style>
