/**
 * 비밀번호 체크. 영문대문자, 소문자, 숫자, 특수문자 중 2가지 이상 조합하여 8자이상
 * @param pwd
 * @returns {boolean}
 */
import { type LocationQueryValue } from 'vue-router';
import Day from 'dayjs';

type FACTORY_TYPE = 'A' | 'B';

export function isBirthdayValid(birthday: string) {
  // YYYYMMDD 형식을 나타내는 정규식
  const regex = /^\d{4}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])$/;
  return regex.test(birthday);
}

export function phoneNumberFormatter(text?: string): string {
  if (!text) {
    return '';
  }

  if (text.startsWith('050')) {
    if (4 < text.length) {
      if (text.length < 9) {
        return text.replace(/^([\d*]{4})([\d*]*)$/, '$1-$2');
      } else if (text.length < 13) {
        return text.replace(/^([\d*]{4})([\d*]*)([\d*]{4})$/, '$1-$2-$3');
      }
    }
  } else if (text.startsWith('02')) {
    if (2 < text.length) {
      if (text.length < 7) {
        return text.replace(/^([\d*]{2})([\d*]*)$/, '$1-$2');
      } else if (text.length < 11) {
        return text.replace(/^([\d*]{2})([\d*]*)([\d*]{4})$/, '$1-$2-$3');
      }
    }
  } else {
    if (3 < text.length) {
      if (text.length < 8) {
        return text.replace(/^([\d*]{3})([\d*]*)$/, '$1-$2');
      } else if (text.length < 12) {
        return text.replace(/^([\d*]{3})([\d*]*)([\d*]{4})$/, '$1-$2-$3');
      }
    }
  }

  switch (text.length) {
    case 9:
      return text.replace(/^([\d*]{2})([\d*]{3})([\d*]{4})$/, '$1-$2-$3');
    case 10:
      return text.replace(/^([\d*]{3})([\d*]{3})([\d*]{4})$/, '$1-$2-$3');
    case 11:
      return text.replace(/^([\d*]{3})([\d*]{4})([\d*]{4})$/, '$1-$2-$3');
    case 12:
      return text.replace(/^([\d*]{4})([\d*]{4})([\d*]{4})$/, '$1-$2-$3');
  }

  return text;
}

export function dayStringOfWeek(data: number) {
  return ['일', '월', '화', '수', '목', '금', '토'][data];
}

export function numberFormatter(data: number) {
  if (data === undefined || data === null) {
    return '0';
  }

  const amount = Number(data);

  if (!isNaN(amount)) {
    if (amount === Math.floor(amount)) {
      return amount.toLocaleString(undefined, {
        minimumFractionDigits: 0,
      });
    } else {
      return amount.toLocaleString(undefined, {
        minimumFractionDigits: 2,
      });
    }
  }

  return `${data}`;
}
/**
 * @param pwd
 * @returns {boolean}
 */
export function isPwd({
  pwd,
  type,
  min,
  max,
}: {
  pwd: string;
  type: FACTORY_TYPE;
  min: number;
  max: number;
}): boolean {
  if (type === 'A') {
    // 비밀번호 체크. 영문대문자, 소문자, 숫자, 특수문자 중 2가지 이상 조합하여 8자이상
    let char_type = 0;
    if (/[a-z]/.test(pwd)) {
      char_type = char_type + 1;
    }
    if (/[A-Z]/.test(pwd)) {
      char_type = char_type + 1;
    }
    if (/[0-9]/.test(pwd)) {
      char_type = char_type + 1;
    }
    /*eslint-disable */
    if (/[~!@#$%\^&*()_+`\-={}|[\]\\:";'<>?,./]/gi.test(pwd)) {
      char_type = char_type + 1;
    }
    /*eslint-enable */

    return !(
      char_type < 2 ||
      (char_type >= 3 && pwd.length < min) ||
      (char_type == 2 && pwd.length < max)
    );
  } else if (type === 'B') {
    // 영문,숫자,특수문자 조합 10자 이상
    const createRegex = (min: number, max: number) => {
      const regexPattern = `^(?=.*[A-Za-z])(?=.*\\d)(?=.*[!@#$%^&*()_+~\\-=,./<>?;:'"|\\\\[\\]{}])[A-Za-z\\d!@#$%^&*()_+~\\-=,./<>?;:'"|\\\\[\\]{}]{${min},${max}}$`;
      return new RegExp(regexPattern);
    };
    const regex = createRegex(min, max);
    return regex.test(pwd);
  }
  return false;
}

/**
 * 이메일 포맷 체크 (RFC 2822)
 * @param email
 * @returns {boolean}
 */
export function isEmail(email: string): boolean {
  return !!email.match(
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
  );
}
/**
 * 휴대폰 번호 체크
 * @param hp
 * @returns {boolean}
 */
export function isMobilePhoneNumber(hp: string): boolean {
  return mobilePhoneRegExp.test(hp);
}
const mobilePhoneRegExp = new RegExp(
  '^((01[016789])([0-9*]{3,4})([0-9*]{4})|)$'
);

/**
 * 아이디 체크
 * @param webId
 * @param minLength
 * @param maxLength
 */
export function isWebId(
  webId: string,
  minLength: number = 4,
  maxLength: number = 12
): boolean {
  const regex = new RegExp(`^[A-Za-z\\d]{${minLength},${maxLength}}$`);
  return regex.test(webId);
}

export function datetimeToDate(datetime: string) {
  return Day(datetime).format('YYYY-MM-DD');
}

export function getValueOfQuery(
  qp: LocationQueryValue | LocationQueryValue[]
): string | undefined {
  return (Array.isArray(qp) ? qp[0] : qp) || undefined;
}

export function dividePhoneNumber(phoneNumber: string) {
  let dividedNumber;
  if (phoneNumber.length === 11) {
    // 11자리 핸드폰 번호인 경우
    dividedNumber = phoneNumber
      .match(/(\d{3})(\d{4})(\d{4})/)
      ?.slice(1) as string[];
  } else if (phoneNumber.length === 10) {
    // 10자리 핸드폰 번호인 경우
    dividedNumber = phoneNumber
      .match(/(\d{3})(\d{3})(\d{4})/)
      ?.slice(1) as string[];
  }
  return dividedNumber;
}

export function isMobileNumber(value: string) {
  const regExp = new RegExp('^010\\d{8}$');
  return regExp.test(value);
}

export function isBirthday(dateStr: string) {
  const dateString = dateStr.replace(/-/g, '');
  if (!/^\d{8}$/.test(dateString)) {
    return false;
  }

  const year = parseInt(dateString.substring(0, 4), 10);
  const month = parseInt(dateString.substring(4, 6), 10) - 1;
  const day = parseInt(dateString.substring(6, 8), 10);

  const date = new Date(year, month, day);

  return (
    date.getFullYear() === year &&
    date.getMonth() === month &&
    date.getDate() === day
  );
}

export function getBirthdayWithHyphen(value: string) {
  return value.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');
}

export const padStringStart = (
  value: string,
  targetLength: number,
  padChar: string = '0'
): string => {
  return value.padStart(targetLength, padChar);
};

export const parseDate = (params: string) => {
  // 정규 표현식을 사용하여 '-' 또는 '.'로 구분된 날짜 문자열을 표준 형식으로 변환
  const normalizedParams = params.replace(/[-.]/g, '-');
  // 날짜 부분만 분리하고 시간 부분은 무시
  const [datePart] = normalizedParams.split(' ');
  const [year, month, date] = datePart.split('-');
  const yyyy = year;
  const mm = month;
  const dd = date.substring(0, 2);
  return {
    yyyy,
    mm,
    dd,
  };
};
/**
 * 주어진 파일들의 총 크기를 계산하여 제한을 초과하는지 확인
 * @param files - 확인할 파일들의 배열
 * @param maxFileSize - 최대 허용 파일 크기 (기본값: 5MB)
 * @returns 파일 크기가 제한을 초과하면 true, 그렇지 않으면 false
 */
export const isTotalFileSizeExceeding = (
  files: File[],
  maxFileSize: number = 5 * 1024 * 1024
): boolean => {
  const totalFileSize = files.reduce(
    (acc: number, file: File) => acc + file.size,
    0
  );
  return totalFileSize > maxFileSize;
};

/**
 * 단일 파일의 크기가 제한을 초과하는지 확인합니다.
 * @param file - 확인할 파일
 * @param maxFileSize - 최대 허용 파일 크기 (기본값: 5MB)
 * @returns 파일 크기가 제한을 초과하면 true, 그렇지 않으면 false
 */
export const isSingleFileSizeExceeding = (
  file: File,
  maxFileSize: number = 5 * 1024 * 1024
): boolean => {
  return file.size > maxFileSize;
};

/**
 * 파일 크기를 사람이 읽을 수 있는 형식으로 변환합니다.
 * @param size - 파일 크기 (바이트 단위)
 * @returns 사람이 읽을 수 있는 파일 크기 문자열
 */
export const formatFileSize = (size: number): string => {
  if (size < 1024) return `${size} bytes`;
  if (size < 1024 * 1024) return `${(size / 1024).toFixed(2)} KB`;
  if (size < 1024 * 1024 * 1024)
    return `${(size / (1024 * 1024)).toFixed(2)} MB`;
  return `${(size / (1024 * 1024 * 1024)).toFixed(2)} GB`;
};

/**
 * 두 파일이 동일한지 비교합니다.
 * @param f1 - 비교할 첫 번째 파일
 * @param f2 - 비교할 두 번째 파일
 * @returns 두 파일이 동일하면 true, 그렇지 않으면 false
 */
export const areFilesEqual = (f1: File, f2: File): boolean => {
  return f1.name === f2.name && f1.size === f2.size && f1.type === f2.type;
};
/**
 base64 이미지 파일을 Blob 파일로 변환
  **/
export const base64ToBlob = (base64WithPrefix: string) => {
  const base64 = base64WithPrefix.replace(/^data:image\/\w+;base64,/, '');
  const byteCharacters = atob(base64);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  // MIME 타입 추출
  const mime = base64WithPrefix.match(/^data:(image\/\w+);base64,/)?.[1] || '';
  return new Blob([byteArray], { type: mime });
};
/**
 * 하이픈을 추가해서 생년월일 포맷변환.
 * @param dateString
 */
export const formatBirthday = (dateString: string): string => {
  if (dateString.length > 6) {
    return dateString.replace(/^(\d{4})(\d{2})(\d*)$/, '$1-$2-$3');
  }
  if (dateString.length > 4) {
    return dateString.replace(/^(\d{4})(\d*)$/, '$1-$2');
  }
  return dateString.replace(/^(\d{0,4})$/, '$1');
};
