import { SignInPayload } from '@core/dto';
import { AuthModel } from '@core/models';
import { AuthAdapter } from '@core/adapters';

export class SignInUseCase {
  constructor(private readonly authAdapter: AuthAdapter) {}
  async execute(payload: SignInPayload): Promise<AuthModel> {
    try {
      return await this.authAdapter.signIn(payload);
    } catch (error) {
      return AuthModel.createEmpty();
    }
  }
}
