import { ROUTE_NAME } from '@constants';

const community = {
  path: '/community',
  redirect: '/community/notice',
  name: ROUTE_NAME.Community,
  meta: {
    breadcrumb: true,
    breadcrumbName: '고객지원',
  },
  children: [
    {
      name: ROUTE_NAME.Notice,
      path: 'notice',
      component: () => import('@views/community/Notice.vue'),
      meta: {
        isReady: false,
        breadcrumb: true,
        breadcrumbName: '공지사항',
      },
    },
    {
      name: ROUTE_NAME.Signup,
      path: 'signup',
      component: () => import('@views/auth/Signup.vue'),
      meta: {
        breadcrumb: true,
        breadcrumbName: '회원가입',
      },
    },
    {
      name: ROUTE_NAME.FindId,
      path: 'find-id',
      component: () => import('@views/auth/FindId.vue'),
      meta: {
        breadcrumb: true,
        breadcrumbName: '아이디/비밀번호 찾기',
      },
    },
    {
      name: ROUTE_NAME.UserInfoModify,
      path: 'user-info-modify',
      component: () => import('@views/auth/UserInfoModify.vue'),
      meta: {
        isAdmin: false,
        breadcrumb: true,
        breadcrumbName: '회원정보 수정',
        requiresAuth: true,
      },
    },
    {
      name: ROUTE_NAME.NoticeWrite,
      path: 'notice/write',
      component: () => import('@views/community/NoticeWrite.vue'),
      meta: {
        isReady: false,
        isAdmin: true,
        breadcrumb: false,
        breadcrumbName: '공지사항 등록',
      },
    },
    {
      name: ROUTE_NAME.NoticeModify,
      path: 'notice/modify/:postId',
      component: () => import('@views/community/NoticeModify.vue'),
      meta: {
        isReady: false,
        breadcrumb: false,
        breadcrumbName: '공지사항 수정',
      },
    },
    {
      name: ROUTE_NAME.NoticeView,
      path: 'notice/:postId',
      component: () => import('@views/community/NoticeView.vue'),
      meta: {
        isReady: false,
        isAdmin: true,
        breadcrumb: false,
        breadcrumbName: '공지사항 상세보기',
      },
    },
    {
      name: ROUTE_NAME.Banner,
      path: 'banner',
      component: () => import('@views/community/Banner.vue'),
      meta: {
        isReady: false,
        isAdmin: true,
        breadcrumb: true,
        breadcrumbName: '배너관리',
      },
    },
    {
      name: ROUTE_NAME.BannerWrite,
      path: 'banner/write',
      component: () => import('@views/community/BannerWrite.vue'),
      meta: {
        isReady: false,
        isAdmin: true,
        breadcrumb: false,
        breadcrumbName: '배너등록',
      },
    },
    {
      name: ROUTE_NAME.BannerModify,
      path: 'banner/modify/:postId',
      component: () => import('@views/community/BannerModify.vue'),
      meta: {
        isReady: false,
        isAdmin: true,
        breadcrumb: false,
        breadcrumbName: '배너수정',
      },
    },
    // {
    //   name: ROUTE_NAME.Archive,
    //   path: 'archive',
    //   component: () => import('@views/community/Archive.vue'),
    //   meta: {
    //     isReady: false,
    //     breadcrumb: true,
    //     breadcrumbName: '자료실',
    //   },
    // },
    // {
    //   name: ROUTE_NAME.ArchiveView,
    //   path: 'archive/:postId',
    //   component: () => import('@views/community/ArchiveView.vue'),
    //   meta: {
    //     isReady: false,
    //     breadcrumb: true,
    //     breadcrumbName: '자료실 상세보기',
    //   },
    // },
    // {
    //   name: ROUTE_NAME.ArchiveWrite,
    //   path: 'archive/write',
    //   component: () => import('@views/community/ArchiveWrite.vue'),
    //   meta: {
    //     isReady: false,
    //     breadcrumb: true,
    //     breadcrumbName: '자료실 등록',
    //   },
    // },
    // {
    //   name: ROUTE_NAME.ArchiveModify,
    //   path: 'archive/modify/:postId',
    //   component: () => import('@views/community/ArchiveModify.vue'),
    //   meta: {
    //     isReady: false,
    //     breadcrumb: true,
    //     breadcrumbName: '자료실 수정',
    //   },
    // },
    // {
    //   name: ROUTE_NAME.PhotoGallery,
    //   path: 'photo-gallery',
    //   component: () => import('@views/community/PhotoGallery.vue'),
    //   meta: {
    //     isReady: false,
    //     breadcrumb: true,
    //     breadcrumbName: '포토갤러리',
    //   },
    // },
  ],
};
export default community;
